$primary: #3cbfe8;
$secondary: #fff;
$success: #27c24c;
$info: #23b7e5;
$warning: #ff902b;
$danger: #f05050;
$dark: #3a3f51;

$font-size-base: 0.875rem;

@import 'bootstrap/scss/functions';

/**
* The following piece of code patches bootstrap to show white text on buttons with our primary color - because it looks pretty: Does not follow WCAG contrast requirements though!
*/
@function color-contrast(
    $background,
    $color-contrast-dark: $color-contrast-dark,
    $color-contrast-light: $color-contrast-light,
    $min-contrast-ratio: $min-contrast-ratio
) {
    @if (
        $background ==
            $dark or
            $background ==
            $primary or
            $background ==
            $success or
            $background ==
            $danger or
            $background ==
            $warning
    ) {
        @return $white;
    }

    $tinted-hover-bg: shade-color($primary, $btn-hover-bg-shade-amount);

    @if ($background == $tinted-hover-bg) {
        @return $white;
    }

    $tinted-active-bg: shade-color($primary, $btn-active-bg-shade-amount);

    @if ($background == $tinted-active-bg) {
        @return $white;
    }

    $foregrounds: $color-contrast-light, $color-contrast-dark, $white, $black;
    $max-ratio: 0;
    $max-ratio-color: null;

    @each $color in $foregrounds {
        $contrast-ratio: contrast-ratio($background, $color);
        @if $contrast-ratio > $min-contrast-ratio {
            @return $color;
        } @else if $contrast-ratio > $max-ratio {
            $max-ratio: $contrast-ratio;
            $max-ratio-color: $color;
        }
    }

    @warn "Found no color leading to #{$min-contrast-ratio}:1 contrast ratio against #{$background}...";

    @return $max-ratio-color;
}

@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';

@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/images';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/dropdown';
@import 'bootstrap/scss/button-group';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/accordion';
@import 'bootstrap/scss/breadcrumb';
@import 'bootstrap/scss/pagination';
@import 'bootstrap/scss/badge';
@import 'bootstrap/scss/alert';
@import 'bootstrap/scss/progress';
@import 'bootstrap/scss/list-group';
@import 'bootstrap/scss/close';
@import 'bootstrap/scss/toasts';
@import 'bootstrap/scss/modal';
@import 'bootstrap/scss/tooltip';
@import 'bootstrap/scss/popover';
@import 'bootstrap/scss/carousel';
@import 'bootstrap/scss/spinners';
@import 'bootstrap/scss/offcanvas';
@import 'bootstrap/scss/placeholders';
@import 'bootstrap/scss/helpers';
@import 'bootstrap/scss/utilities/api';

:root {
    --fc-button-active-bg-color: #{shade-color($primary, 10%)};
    --fc-button-active-border-color: transparent;
    --fc-button-bg-color: #{$primary};
    --fc-button-border-color: transparent;
    --fc-button-hover-bg-color: #{shade-color($primary, 10%)};
    --fc-button-hover-border-color: transparent;
}
.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: none !important;
}

.fc .fc-button-primary:focus {
    box-shadow: none !important;
}

.fc a {
    text-decoration: none;
    color: $black;
}

body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    overflow-y: hidden;
}

.thumbnail-128 {
    width: 128px;
    height: 128px;
}

.tab-navigation {
    display: flex;
    background-color: $gray-200;
}

.border-end-primary-dark {
    border-right: 1px solid shade-color($primary, 5%);
    border-bottom: 1px solid shade-color($primary, 5%);
}

$nice-dates-color-gray-dark: $primary;
$nice-dates-color-gray: #999;
$nice-dates-color-gray-light: #ddd;
$nice-dates-color-accent: $nice-dates-color-gray-dark;

@import '@hennber/react-nice-dates/src/style';

.nice-dates {
    width: 100%;
}

.nice-dates-popover {
    z-index: 2222 !important;
}

.logoBox {
    background-color: $info;
}

.form-section {
    & > div {
        width: 100%;
    }
}
